<template>
  <div>
    <div style="word-break: break-word;word-break: break-all" v-if="!isPaper">
      <CheckboxGroup v-model="value">
        <Checkbox
          v-for="(answer, optionIndex) in question.question.options"
          :label="answer.content"
          :key="optionIndex"
          style="display: flex;margin: 10px 0 0 10px"
          :id="checkboxId(answer.content)"
          disabled
        >
          {{ String.fromCharCode(optionIndex + 65) }}.&nbsp;
          <span @click="changeImg($event)" v-html="answer.content.replace(/<\/?p[^>]*>/gi, '').replace(/\<img src=/gi, replaceImg)"></span>
        </Checkbox>
      </CheckboxGroup>
      <div v-if="question.resultStatus !== 0">
        <Alert type="success" style="margin-top: 10px">
          正确答案：
          <span slot="desc" v-for="(answer, index) in correct" :key="index">
            {{ String.fromCharCode(answer + 65) }}
          </span>
        </Alert>
        <Alert type="info" style="margin-top: 10px" v-if="question.question.analysis">
          答案解析：
          <span slot="desc" v-html="question.question.analysis.replace(/\<\/?p(?!re)([^>]+)?\>/g, '')" style="color: #606266"></span>
        </Alert>
      </div>
    </div>
    <div class="paper" style="word-break: break-word;word-break: break-all" v-else>
      <CheckboxGroup v-model="correctContent">
        <Checkbox
          v-for="(answer, optionIndex) in question.options"
          :label="answer.content"
          :key="optionIndex"
          style="display: flex;margin: 10px 0 0 10px"
          :id="checkboxId(answer.content)"
          disabled
        >
          {{ String.fromCharCode(optionIndex + 65) }}.&nbsp;
          <span @click="changeImg($event)" v-html="answer.content.replace(/<\/?p[^>]*>/gi, '').replace(/\<img src=/gi, replaceImg)"></span>
        </Checkbox>
      </CheckboxGroup>
      <div>
        <Alert type="info" style="margin-top: 10px" v-if="question.analysis">
          答案解析：
          <span slot="desc" v-html="question.analysis.replace(/\<\/?p(?!re)([^>]+)?\>/g, '')" style="color: #606266"></span>
        </Alert>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'MultipleChoice',
  props: {
    question: {
      type: Object,
      default: function() {
        return {}
      }
    },
    answer: {
      type: String,
      default: ''
    },
    isPaper: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      value: this.answer === '' || this.answer === null ? [] : JSON.parse(this.answer),
      replaceImg: '<br /><img style="max-width:100%;height:auto" src=',
      correct: [],
      correctContent: []
    }
  },
  created() {
    let q = this.isPaper ? this.question : this.question.question
    q.options.forEach((item, index) => {
      if (item.correct === true) {
        this.correct.push(index)
        this.correctContent.push(item.content)
      }
    })
  },
  methods: {
    changeImg(e) {
      this.$emit('changeBigImg', e)
    },
    checkboxId(index) {
      if (this.answer !== '' && this.answer !== null) {
        let answer = JSON.parse(this.answer)
        let a = this.getArrEqual(answer, this.correctContent)
        if (answer.includes(index)) {
          if (a.includes(index)) {
            return 'true'
          } else {
            return 'false'
          }
        } else {
          return ''
        }
      } else {
        return ''
      }
    },
    getArrEqual(arr1, arr2) {
      let newArr = []
      for (let i = 0; i < arr2.length; i++) {
        for (let j = 0; j < arr1.length; j++) {
          if (arr1[j] === arr2[i]) {
            newArr.push(arr1[j])
          }
        }
      }
      return newArr
    }
  },
  watch: {
    answer(val) {
      val.answer === '' ? (this.value = []) : (this.value = JSON.parse(val))
    }
  }
}
</script>

<style scoped lang="less">
::v-deep .ivu-checkbox-group {
  color: #5e6470;
  span {
    color: #5e6470;
  }
  #true {
    color: #67c23a;
    .ivu-checkbox-checked {
      .ivu-checkbox-inner {
        background-color: #67c23a;
      }
    }
    span {
      color: #67c23a;
    }
  }

  #false {
    color: #f56c6c;
    .ivu-checkbox-checked {
      .ivu-checkbox-inner {
        background-color: #f56c6c;
      }
    }
    span {
      color: #f56c6c;
    }
  }
}
.paper ::v-deep .ivu-checkbox-disabled.ivu-checkbox-checked .ivu-checkbox-inner {
  border-color: #6a8abe;
  background-color: #6a8abe;
  &:after {
    border-color: white;
  }
}
</style>
