<template>
  <div>
    <div style="word-break: break-word;word-break: break-all" v-if="!isPaper">
      <Alert :type="question.resultStatus === 0 ? 'success' : 'error'" v-if="value !== '<pre></pre>'">
        用户答案：
        <span slot="desc" v-html="value"></span>
      </Alert>
      <Alert type="info" style="margin-top: 10px" v-if="question.question.analysis">
        答案解析：
        <span slot="desc" v-html="question.question.analysis.replace(/\<\/?p(?!re)([^>]+)?\>/g, '')" style="color: #606266"></span>
      </Alert>
    </div>
    <div style="word-break: break-word;word-break: break-all" v-else>
      <Alert type="info" style="margin-top: 10px" v-if="question.analysis">
        答案解析：
        <span slot="desc" v-html="question.analysis.replace(/\<\/?p(?!re)([^>]+)?\>/g, '')" style="color: #606266"></span>
      </Alert>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Code',
  props: {
    question: {
      type: Object,
      default: function() {
        return {}
      }
    },
    answer: {
      type: String,
      default: ''
    },
    isPaper: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      value: '<pre>' + this.answer + '</pre>',
      correct: ''
    }
  }
}
</script>

<style scoped lang="less"></style>
